import { OpenNavButton } from './OpenNavButton';
import { Link } from 'react-router-dom';
import { UserIcon } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { Button } from '@mosey/components/buttons/Button';
import { useUser } from '../../hooks/useUser';
import { useHandbook } from '../../hooks/useHandbook';

export const HandbookHeader = () => {
  const { legal_entity } = useUser();
  const { is_acknowledged } = useHandbook();
  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <OpenNavButton />
      <h1 className="flex flex-1 items-center text-medium font-semibold">
        <span className="hidden lg:inline">{legal_entity.name}&nbsp;</span>
        Employee Handbook
      </h1>
      <div className="flex items-center">
        {!is_acknowledged && (
          <>
            <p className="mr-6 hidden text-sm font-semibold text-zinc-700 md:block">
              Your signature is required
            </p>
            <div className="mr-4">
              <Button as={Link} to="/handbook/acknowledge">
                Sign now
              </Button>
            </div>
          </>
        )}
        <DropdownMenu
          isIconButton
          ariaButtonText="Open user menu"
          buttonContent={
            <UserIcon className="mt-1.5 size-8 rounded-full border border-sage-600 p-1 text-gray-500" />
          }
        >
          <MenuItem key="Sign out" as={Link} to="/logout">
            Sign Out
          </MenuItem>
        </DropdownMenu>
      </div>
    </div>
  );
};
