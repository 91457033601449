import { Policy as TPolicy } from '../../types/policy-handbook';
import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';
import { PolicyResources } from './PolicyResources';
import { PolicyTitle } from './PolicyTitle';

type PolicyProps = {
  policy: TPolicy;
};

export const Policy = ({ policy }: PolicyProps) => {
  return (
    <div className="flex flex-col items-stretch gap-3 overflow-hidden">
      <PolicyTitle slug={policy.slug}>{policy.title}</PolicyTitle>
      <div className="flex flex-col gap-4 text-zinc-700">
        {policy.content.main.map((content, index) => (
          <PolicyContentRenderer
            key={index}
            content={content}
            isLoneContent={policy.content.main.length === 1}
          />
        ))}
      </div>
      {policy.content.resources && policy.content.resources.length > 0 && (
        <PolicyResources resources={policy.content.resources} />
      )}
      {policy.content.full_details && (
        <PolicyFullDetails fullDetails={policy.content.full_details} />
      )}
    </div>
  );
};
