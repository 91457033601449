import { Fragment, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { CloseIcon as XMarkIcon } from '@mosey/components/Icons';
import { NavigationContext } from '../../contexts/NavigationContext';

type AppWrapperProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  leftNav: React.ReactNode;
};

// based off https://tailwindui.com/components/application-ui/application-shells/sidebar#component-b8d00b1bdd11267c5f7456532e658a2f
export default function AppWrapper({
  children,
  header,
  leftNav,
}: AppWrapperProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <NavigationContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      <div className="min-h-screen flex flex-col">
        <Transition show={isSidebarOpen} as={Fragment}>
          <Dialog
            className="relative z-50 lg:hidden"
            onClose={setIsSidebarOpen}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setIsSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="size-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  {leftNav && (
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white">
                      {leftNav}
                    </div>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}

        <div className="w-full fixed top-0 z-50">{header}</div>

        <div className="flex flex-grow">
          {leftNav && (
            <div className="hidden lg:flex lg:flex-col lg:w-72 lg:fixed lg:top-16 lg:bottom-0 bg-white border-r border-gray-200">
              <div className="flex flex-col w-72 h-full">{leftNav}</div>
            </div>
          )}

          <div
            className={`flex-grow ${
              leftNav ? 'lg:pl-72' : ''
            } overflow-y-auto pt-16`}
          >
            <main>{children}</main>
          </div>
        </div>
      </div>
    </NavigationContext.Provider>
  );
}
