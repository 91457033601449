import { redirect } from 'react-router-dom';
import { parseHandbookFromApi } from '../utils/handbook';
import { api } from '../utils/api';

export async function handbookLoader() {
  const token = localStorage.getItem('token');
  if (!token) {
    // this will also be triggered on API errors
    // once handbook endpoints are ready
    return redirect('/login');
  }

  return await api
    .get({ url: `/api/handbook/latest` })
    .then((response) => response.json())
    .then((data) => parseHandbookFromApi(data));
}
