import { createContext } from 'react';

type NavigationContextType = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
};
export const NavigationContext = createContext<NavigationContextType>({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
});
