import { Navigate, useParams } from 'react-router-dom';
import { PolicySection } from '../components/policy-details/PolicySection';
import AppWrapper from '../components/application/AppWrapper';
import { HandbookNavigation } from '../components/application/HandbookNavigation';
import { HandbookHeader } from '../components/application/HandbookHeader';
import { useHandbook } from '../hooks/useHandbook';

export const Handbook = () => {
  const policyData = useHandbook();
  const { policySectionId } = useParams<{ policySectionId: string }>();

  const selectedSection = policyData.sections.find(
    (cat) => cat.id === policySectionId,
  );

  if (!selectedSection) {
    return <Navigate replace to={`/handbook/${policyData.sections[0].id}`} />;
  }

  const nextSectionIndex =
    policyData.sections.findIndex((item) => item.id === selectedSection.id) + 1;

  return (
    <AppWrapper
      header={<HandbookHeader />}
      leftNav={<HandbookNavigation sections={policyData.sections} />}
    >
      <PolicySection
        selectedSection={selectedSection}
        nextSection={policyData.sections[nextSectionIndex]}
      />
    </AppWrapper>
  );
};
