import { Api } from '@mosey/utils/data/api';
import { API_URL } from '../config';
import { getAccessToken } from '@mosey/utils/auth';
import { refreshHandbookToken } from './auth';

export const api = new Api({
  baseUrl: API_URL,
  getToken: getAccessToken,
  refreshToken: refreshHandbookToken,
});
