import {
  ApiHandbook,
  CategoryListItem,
  Handbook,
  PolicySection,
} from '../types/policy-handbook';
import * as icons from '@heroicons/react/outline';
export function parseHandbookFromApi({
  policy_instances,
  ...rest
}: ApiHandbook): Handbook {
  const sections: { [key: string]: PolicySection } = {};
  const categories: Record<string, CategoryListItem> = {};

  for (const policyInstance of policy_instances) {
    if (!sections[policyInstance.policy_section.id]) {
      sections[policyInstance.policy_section.id] = {
        ...policyInstance.policy_section,
        icon: policyInstance.policy_section.icon as keyof typeof icons,
        items: [],
      };
    }
    if (policyInstance.policy_category) {
      let category = categories[policyInstance.policy_category.id];
      if (!category) {
        category = {
          type: 'category',
          sectionId: policyInstance.policy_section.id,
          slug: policyInstance.slug,
          title: policyInstance.title,
        };
        sections[policyInstance.policy_section.id].items.push(category);
        categories[policyInstance.policy_category.id] = category;
      }
      if (policyInstance.is_custom_policy) {
        category.customPolicy = policyInstance;
        category.slug = policyInstance.slug;
        category.title = policyInstance.title;
      } else {
        category.regionPolicy = policyInstance;
      }
    } else {
      sections[policyInstance.policy_section.id].items.push({
        type: 'policy',
        title: policyInstance.title,
        sectionId: policyInstance.policy_section.id,
        policy: policyInstance,
        slug: policyInstance.slug,
      });
    }
  }

  return {
    ...rest,
    sections: Object.values(sections).sort((a, b) => a.order - b.order),
  };
}
