import { clsx } from 'clsx';
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItems,
  MenuItem as HeadlessUIMenuItem,
  MenuItemProps as HeadlessUIMenuItemProps,
} from '@headlessui/react';
import { SelectIcon, CheckIcon } from '../Icons';
import * as styles from './styles';
import { MenuTransition } from './common/MenuTransition';

interface DropdownProps {
  children: React.ReactNode;
  buttonContent?: MenuButtonProps['children'];
  isIconButton?: boolean;
  ButtonLeftIcon?: React.ElementType;
  buttonText?: string;
  ariaButtonText?: string;
  isFullWidth?: boolean;
}

export const DropdownMenu = ({
  children,
  buttonContent,
  isIconButton = false,
  ButtonLeftIcon,
  buttonText = '',
  ariaButtonText,
  isFullWidth = false,
}: DropdownProps) => {
  return (
    <Menu
      as="div"
      className={clsx('relative text-left leading-none', {
        'w-full': !isIconButton && isFullWidth,
      })}
    >
      <MenuButton
        className={clsx(
          isIconButton
            ? 'rounded-full outline-none outline-offset-2 data-[focus]:outline-2 data-[focus]:outline-rose-700'
            : 'my-px flex min-w-[210px] max-w-none items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm outline-none ring-1 ring-sage-600 hover:bg-gray-50 focus:ring-2 focus:ring-rose-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
          !isIconButton && isFullWidth && 'w-full',
        )}
        aria-label={
          ariaButtonText
            ? `${ariaButtonText}${buttonText ? ` ${buttonText}` : ''}`
            : ''
        }
      >
        {buttonContent || (
          <>
            {ButtonLeftIcon && (
              <ButtonLeftIcon className={styles.icon} aria-hidden="true" />
            )}
            {buttonText}
            <SelectIcon
              className={clsx(styles.icon, 'ml-auto')}
              aria-hidden="true"
            />
          </>
        )}
      </MenuButton>

      <MenuTransition>
        <MenuItems
          className={clsx(
            styles.container,
            'max-h-60 origin-top-right overflow-auto py-1',
            isFullWidth ? 'w-full' : 'w-56',
          )}
        >
          {children}
        </MenuItems>
      </MenuTransition>
    </Menu>
  );
};

interface MenuItemProps {
  LeftIcon?: React.ElementType;
  children: React.ReactNode;
  ariaLabel?: string;
  selected?: boolean;
}

export const MenuItem = <T extends React.ElementType>({
  LeftIcon,
  children,
  ariaLabel,
  selected = false,
  ...rest
}: MenuItemProps & HeadlessUIMenuItemProps<T>) => {
  return (
    <HeadlessUIMenuItem
      {...rest}
      className={clsx(styles.item, {
        'font-bold': selected,
      })}
      aria-current={selected}
      aria-label={ariaLabel}
    >
      {LeftIcon && <LeftIcon className={styles.icon} aria-hidden="true" />}
      {children}
      {selected && (
        <CheckIcon
          className={clsx(styles.iconBase, 'ml-auto text-green-600')}
          aria-hidden="true"
        />
      )}
    </HeadlessUIMenuItem>
  );
};
