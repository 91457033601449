import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { NotFound } from './NotFound';
import { HandbookUnavailable } from './HandbookUnavailable';

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  return <HandbookUnavailable />;
};
