import { FunctionComponent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

type NavItemProps = {
  name: string;
  Icon: React.ElementType<{ className?: string }>;
  to: string;
  isClosed: boolean;
  isDisabled: boolean;
  disabledTooltipMessage: string;
  children?: React.ReactNode;
  isExact?: boolean;
  onClick?: () => void;
};

export const NavItem: FunctionComponent<NavItemProps> = ({
  name,
  Icon,
  to,
  isClosed,
  isDisabled,
  disabledTooltipMessage,
  children,
  onClick,
  isExact = false,
}) => {
  const location = useLocation();
  const toRoot = to.split('/')[1];
  const currentRoot = location.pathname.split('/')[1];
  const isActive = isExact ? to === location.pathname : toRoot === currentRoot;

  return (
    <div className="group relative mb-5">
      <div className="relative">
        {isDisabled && (
          <div className="absolute bottom-0 mb-6 flex flex-col items-center">
            <span className="relative z-10 hidden min-w-48 whitespace-nowrap rounded bg-black p-2 text-xs leading-none text-white shadow-lg group-hover:flex">
              {disabledTooltipMessage}
            </span>
          </div>
        )}
        <NavLink
          to={to}
          className={({ isActive: navIsActive }) =>
            clsx('flex items-center border-white', {
              'text-gray-400 pointer-events-none': isDisabled,
              'text-rose-700 motion-reduce:transition-none': navIsActive,
            })
          }
          onClick={onClick}
        >
          <div
            className={clsx('mx-auto w-6', {
              'text-rose-700': isActive,
              'text-gray-300 group-hover:text-gray-600': !isActive,
            })}
          >
            <Icon className="size-5" />
          </div>
          <div
            data-testid={`navLink-${name}`}
            className={clsx(
              'flex-1 overflow-hidden truncate text-base font-semibold leading-6 transition-all duration-300 ease-in-out',
              {
                'opacity-0 w-0': isClosed,
                'w-24': !isClosed,
              },
            )}
          >
            <span className="ml-2">{name}</span>
          </div>
        </NavLink>
        {children && !isClosed && (
          <ul
            className={clsx('text-base leading-6', {
              'ml-6 my-1': isActive,
              hidden: !isActive,
            })}
          >
            {children}
          </ul>
        )}
      </div>
    </div>
  );
};
