import { RefreshTokenError, setAccessToken } from '@mosey/utils/auth';
import { API_URL } from '../config';

export const redeemToken = async (token: string) => {
  const response = await fetch(`${API_URL}/api/handbook/auth/redeem_token`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export const resendLink = async (token: string) => {
  const response = await fetch(`${API_URL}/api/handbook/auth/resend_link`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const requestLink = async (email: string) => {
  const response = await fetch(`${API_URL}/api/handbook/auth/email`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

/*
Get a new access token for the user.
*/
export const refreshHandbookToken = async () => {
  const request = new Request(`${API_URL}/api/handbook/auth/refresh_token`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw RefreshTokenError.InternalServerError;
  }

  if (response.status === 401) {
    throw RefreshTokenError.Expired;
  }

  const data = await response.json();

  if ('access_token' in data) {
    setAccessToken(data.access_token);
  }

  return data;
};
