import { CategoryListItem } from '../../types/policy-handbook';
import { Policy } from './Policy';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';
import { RegionScopedContent } from '@mosey/components/handbook/RegionScopedContent';
import { PolicyTitle } from './PolicyTitle';
import { CompanyWidePolicyContent } from '@mosey/components/handbook/CompanyWidePolicyContent';

type PolicyCategoryProps = {
  category: CategoryListItem;
};

export const PolicyCategory = ({
  category: { customPolicy, regionPolicy, slug, title },
}: PolicyCategoryProps) => {
  if (!customPolicy && !regionPolicy) {
    return null;
  } else if (!(customPolicy && regionPolicy)) {
    // has one, but not both
    const singularPolicy = (customPolicy || regionPolicy)!;
    return <Policy policy={singularPolicy} />;
  } else {
    return (
      <div className="flex flex-col items-stretch gap-4 overflow-hidden">
        <PolicyTitle slug={slug}>{title}</PolicyTitle>
        <CompanyWidePolicyContent mainContent={customPolicy.content.main} />
        <div className="space-y-2">
          <RegionScopedContent
            mainContent={regionPolicy.content.main}
            regions={
              regionPolicy.policy_scope?.region?.name
                ? [regionPolicy.policy_scope?.region?.name]
                : []
            }
          />
          {regionPolicy.content.full_details && (
            <PolicyFullDetails
              fullDetails={regionPolicy.content.full_details}
            />
          )}
        </div>
      </div>
    );
  }
};
