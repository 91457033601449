import { HTMLProps } from 'react';

type PolicyTitleProps = Omit<
  HTMLProps<HTMLHeadingElement>,
  'className' | 'id'
> & {
  slug: string;
};

export const PolicyTitle = ({ slug, children, ...props }: PolicyTitleProps) => {
  return (
    <h2
      className="flex min-h-[60px] items-center border-b border-gray-200 py-2 font-semibold"
      id={slug}
      {...props}
    >
      {children}
    </h2>
  );
};
