import { NavItem } from '@mosey/components/navigation/NavItem';
import { SubNavItem } from '@mosey/components/navigation/SubNavItem';
import { PolicySection } from '../../types/policy-handbook';
import { useContext } from 'react';
import { NavigationContext } from '../../contexts/NavigationContext';
import * as icons from '@heroicons/react/outline';

type AppNavigationProps = {
  sections: Array<PolicySection>;
};

export const HandbookNavigation = ({ sections }: AppNavigationProps) => {
  const { setIsSidebarOpen } = useContext(NavigationContext);
  return (
    <nav className="flex flex-1 flex-col overflow-y-auto">
      <ul className="flex flex-1 flex-col gap-2 overflow-y-auto px-7 pb-4 pt-8 text-gray-600">
        {sections.map((section) => (
          <NavItem
            key={section.id}
            name={section.title}
            to={`/handbook/${section.id}`}
            isClosed={false}
            isDisabled={false}
            disabledTooltipMessage=""
            // eslint-disable-next-line import/namespace
            Icon={icons[section.icon] || icons.FolderIcon}
            onClick={() => setIsSidebarOpen(false)}
            isExact
          >
            {section.items.map((item) => (
              <SubNavItem
                key={item.slug}
                name={item.title!}
                to={`/handbook/${section.id}#${item.slug}`}
                isExact
                onClick={() => setIsSidebarOpen(false)}
              />
            ))}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
};
