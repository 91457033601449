import { LoadingIcon } from '@mosey/components/Icons';
import { NarrowWrapper } from '../../components/application/NarrowWrapper';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { redeemToken } from '../../utils/auth';
import { setAccessToken } from '@mosey/utils/auth';

export const Token = () => {
  return (
    <NarrowWrapper>
      <LoadingIcon className="size-16" />
    </NarrowWrapper>
  );
};

Token.loader = async ({ request }: LoaderFunctionArgs) => {
  const urlSearchParams = new URL(request.url).searchParams;
  const token = urlSearchParams.get('token');
  if (!token) {
    return redirect('/login');
  }

  try {
    const { access_token } = await redeemToken(
      urlSearchParams.get('token') as string,
    );
    setAccessToken(access_token);
    return redirect('/handbook');
  } catch (err) {
    if ((err as Response)?.status === 410) {
      // its expired or already used, we can allow user to re-send the email
      const responseBody = await (err as Response).json();
      const params = new URLSearchParams({
        token,
        email: responseBody.detail?.email,
      });
      throw redirect(`/request-new-link?${params.toString()}`);
    } else {
      return redirect('/login?error=invalid-token');
    }
  }
};
