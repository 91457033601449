import { FunctionComponent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

type SubNavItemProps = {
  name: string;
  to: string;
  isExact?: boolean;
  onClick?: () => void;
};

export const SubNavItem: FunctionComponent<SubNavItemProps> = ({
  name,
  to,
  onClick,
  isExact = false,
}) => {
  const location = useLocation();
  const currentRelativePath = location.pathname + location.hash;
  return (
    <div className="ml-2 mt-2 ">
      <NavLink
        to={to}
        className={({ isActive }) =>
          clsx('hover:underline', {
            'font-semibold':
              isActive && (!isExact || currentRelativePath === to),
          })
        }
        onClick={onClick}
      >
        <span className="mr-2 text-gray-600 whitespace-normal text-sm">
          {name}
        </span>
      </NavLink>
    </div>
  );
};
