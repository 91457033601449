import { CheckCircleIcon } from '@heroicons/react/solid';
import { CheckBox } from '@mosey/components/forms/CheckBox';
import { NarrowWrapper } from '../components/application/NarrowWrapper';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Button } from '@mosey/components/buttons/Button';
import {
  ActionFunctionArgs,
  Form,
  Link,
  Navigate,
  useActionData,
  useNavigation,
} from 'react-router-dom';
import { api } from '../utils/api';
import { useHandbook } from '../hooks/useHandbook';

export const Acknowledge = () => {
  const { id, is_acknowledged } = useHandbook();
  const { state } = useNavigation();
  const actionData = useActionData() as
    | { success?: boolean; error?: unknown }
    | undefined;

  if (actionData?.success) {
    return (
      <NarrowWrapper showLogo={false}>
        <div className="flex flex-col items-center gap-4">
          <CheckCircleIcon className="size-12 text-teal-700" />
          <h1 className="text-2xl font-bold">
            You&apos;ve signed the handbook
          </h1>
          <div className="flex items-center gap-4 py-4">
            <Button variant="secondary" as={Link} to="/logout">
              Sign out
            </Button>
            <Button as={Link} to="/handbook">
              Return to handbook
            </Button>
          </div>
        </div>
      </NarrowWrapper>
    );
  }

  if (is_acknowledged) {
    return <Navigate to="/handbook" />;
  }
  return (
    <NarrowWrapper showLogo={false}>
      <Form className="flex flex-col gap-4 rounded border p-8" method="POST">
        <h1 className="text-2xl font-semibold text-black">Acknowledgement</h1>
        <CheckBox
          id="acknowledge"
          label="I have read and understand these policies, and I adopt my typed name below as my signature"
          labelClassName="text-zinc-800"
          className="m-0"
          inputProps={{ required: true, name: 'acknowledge' }}
        />
        <label>
          <span className="mb-2 font-semibold text-zinc-800">
            Type your name:
          </span>
          <input
            required
            type="text"
            name="name"
            className="mt-1 block w-full rounded border border-gray-300 px-3 py-2 font-[cursive] text-[36px] leading-6"
          />
        </label>
        <div className="flex items-center justify-between py-4">
          <TextLink to="/handbook" variant="secondary">
            Return to handbook
          </TextLink>
          <Button name="handbook" value={id} isLoading={state !== 'idle'}>
            Submit
          </Button>
        </div>
      </Form>
    </NarrowWrapper>
  );
};

Acknowledge.action = async ({ request }: ActionFunctionArgs) => {
  const data = await request.formData();
  try {
    await api.post({
      url: `/api/handbook/${data.get('handbook')}/acknowledge`,
      body: {
        name: data.get('name'),
      },
    });
    return {
      success: true,
    };
  } catch (err) {
    return {
      error: err,
    };
  }
};
