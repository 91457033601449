import { useContext } from 'react';
import { NavigationContext } from '../../contexts/NavigationContext';
import { HamburgerIcon } from '@mosey/components/Icons';

export const OpenNavButton = () => {
  const { setIsSidebarOpen } = useContext(NavigationContext);
  return (
    <button
      type="button"
      className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      onClick={() => setIsSidebarOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <HamburgerIcon className="size-6" aria-hidden="true" />
    </button>
  );
};
