import clsx from 'clsx';
import logo from '../../assets/logo.svg';

type NarrowWrapperProps = {
  showLogo?: boolean;
  children: React.ReactNode;
};

export const NarrowWrapper = ({
  children,
  showLogo = true,
}: NarrowWrapperProps) => {
  return (
    <main className="mx-auto flex min-h-screen max-w-7xl flex-col justify-center p-6 sm:p-12 lg:p-16">
      <div className="mx-auto flex max-w-md flex-col items-start justify-center">
        {showLogo && (
          <img src={logo} alt="Mosey logo" className="mb-20 h-7 w-auto" />
        )}
        <div
          className={clsx('flex flex-col items-start gap-8', {
            'pb-28': showLogo,
          })}
        >
          {children}
        </div>
      </div>
    </main>
  );
};
